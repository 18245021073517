import React, { useState , useEffect, useContext } from "react";
import "../../src/main.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "video-react/dist/video-react.css";
import { useTranslation } from "react-i18next";
import {AiOutlineArrowRight} from "react-icons/ai";
import { localhostURL } from "../common/baseUrl";
import {AdContext, AdIdContext} from './adManagerContext'
import { StyleSheet, View,Linking} from 'react-native';
import Carousel from './incCarousel';
import { randomSites,LandingUrls } from "../common/randomSites";
import Logo from "./logoComponent"
import { userVisit, setSessionFn } from '../common/commanfn';
import {InsAdSopt} from './ad3i';  

const ActiveFreeWIFI = () => {
  // eslint-disable-next-line
  const {addManager, setAddManager} = useContext(AdContext);
  const {locationPath, bunConfig} = useContext(AdIdContext); 
  const configTimeout = bunConfig[0]?.CP_Connected;
  const [seconds, setSeconds] = useState(configTimeout)
  const [adSopts1, setAdSopts1] = useState([]);
  const [adSopts2, setAdSopts2] = useState([]);
  const [resMessage,setresMessage] = useState('')
  const [adSopts, setAdSopts] =useState(addManager?.filter(itm => 
    itm?.ad_container === "CP_Interstitial"
    ));
  const [ad1, setAd1] = useState(addManager?.find(itm => itm.ad_container=="CP_Interstitial"));
  
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds)=> {
		if(seconds>0) {
			return  seconds-1;
		} else {
			clearInterval(interval);
			connectToWifi();
			return 0;
		}
	});
    }, 1000);
    return () => clearInterval(interval);
}, [configTimeout]);
	
useEffect(() =>{
   const CP_Interstitial = addManager?.filter(itm => itm?.ad_container === "CP_Interstitial");
   setAdSopts1(CP_Interstitial.splice(0,3))
   setAdSopts2(CP_Interstitial.splice(0,3))
  //  console.log("CP_Interstitial", CP_Interstitial, CP_Interstitial.splice(0,3), CP_Interstitial.splice(0,3))
    setAd1(addManager?.find(itm => itm?.ad_container === "CP_Interstitial"));
  },[addManager])
  // useEffect(() => {
  //   console.log("1=>",adSopts?.splice(0,3),  adSopts?.splice(0,3), adSopts)
  //   setAdSopts1((adSopts.length > 0) ? adSopts?.splice(0,3) : [])
  //   setAdSopts2((adSopts.length > 3) ? adSopts?.splice(3,6) : [])
  // },[adSopts])
  const navigate = useNavigate();
  const { t } = useTranslation();

  const number = JSON.parse(localStorage.getItem("phoneNumber"));
  const reqBody=JSON.parse(window.localStorage.getItem("reqBody"));
  const customerData= JSON.parse(localStorage.getItem("cusData"));

  const verifyFreeSub = async () => {
    //const customerData= JSON.parse(localStorage.getItem("cusData"));
    if(number){
      reqBody.mobileno= number;
    }
   
    const postData={
      reqBody: reqBody,
      custdata: customerData
    };
    await fetch(
      `${localhostURL}/api/v1/radius/verifyFreeWIFI`,
      {
        method: "POST",
        body: JSON.stringify(postData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then(async (data) => {
        setresMessage(data.response.success);
        if (data.response.success == true) {
          await checkFreeSubs()
          //navigate("/activeFreewifiPlan");
        } else {
          alert("Free plan limit exhausted for the day.");
       
        }
      })
      .catch((err) => {
        console.log(err.messsage);
      });
  };

   const checkFreeSubs = async () => {
    const customerData= JSON.parse(localStorage.getItem("cusData"));
    reqBody.mobileno= number;
    const postData={
      reqBody: reqBody,
      custdata: customerData
    };
    await fetch(
      `${localhostURL}/api/v1/radius/activateFreeWIFI`,
      {
        method: "POST",
        body: JSON.stringify(postData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response) => response.json())
    .then((data) => {
      if (data.response.success === true) {
        //alert('Free WIFI Activated');
        navigate("/wifiConnected");
      }
    }).catch((err) => {
      console.log(err.messsage);
    });
  };
      
  const openRandomURL = () => {
    const randomIndex = Math.floor(Math.random() * randomSites.length);
    const randomURL = randomSites[randomIndex];
    window.open(randomURL, '_blank'); // Opens in a new tab
  };

  const connectToWifi = () => {
    verifyFreeSub();
      //checkFreeSubs();
      setTimeout(() => {
        openRandomURL()
       
         }, "10000");
        
  };
  useEffect(() => { 
    setSessionFn();
    userVisit({interstitial: 1});
   },[]);
  
  return (
    <div className="header-main">
      {/* <Logo addManager={addManager} adSoptIdhdr={adSoptIdhdr} /> */}

      {/* <div className="link-user-survey">
        <span className="links-a-user1" onClick={() => { navigate('/user')}}>
        {t("description.userLogin")}
        </span>
      </div> */}
      
      <div className="btn-header">
        
        <Button
          variant="primary"
          className="wifi-btn-ad"
          onClick={() => connectToWifi()}
          disabled={seconds > 0}
        >
          {t("description.skipad")}
          {seconds > 0  ?  <>
            <div className="spinner-grow"  role="status">...</div>
            <>{seconds}</>
          </> : null}
          <AiOutlineArrowRight className="arrow_right"></AiOutlineArrowRight>
        </Button>
      </div>
      {/* {console.log("data=>",  adSopts2, adSopts1)} */}
      <Carousel key={1} adSopts={adSopts1} locationPath={locationPath} adSopts2={adSopts2}></Carousel> 
      {/* {adSopts1 && adSopts1.length > 1 ?(
       <Carousel key={1} adSopts={adSopts1} locationPath={locationPath} adSopts2={adSopts2}></Carousel>):
       <ins key={adSopts?.SrNo} adSoptData={adSopts} locationPath={locationPath}/>
      } */}
      
      {/* <FooterComponent /> */}
    </div>
  );
};

export default ActiveFreeWIFI;
